<template>
  <div
    class="w-full flex-none border-b border-gray-600 bg-gradient-to-br from-purple-700 to-purple-900 dark:from-purple-900 dark:to-slate-800 text-white"
  >
    <div class="px-4 md:container md:mx-auto flex">
      <router-link to="/"
        ><img src="@/assets/odlogo.svg" class="mt-1 h-14 md:mt-2 md:h-24 lg:mt-4 lg:h-36"
      /></router-link>
      <div class="grow flex flex-col content-center">
        <div class="grow hidden md:block text-right text-sm mt-2">
          Up next: {{ upNext }}
        </div>
        <div
          class="flex h-full md:h-auto items-center justify-end -mr-1 md:mb-1 text-sm sm:text-base nav-links"
        >
          <router-link to="/live">Live</router-link>
          <router-link to="/again">Watch again</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    upNext: String,
  },
};
</script>

<style scoped>
.nav-links > a {
  @apply inline-flex items-center transition-all mx-1 px-2 my-2 h-10 sm:h-8 rounded-md hover:text-purple-800 hover:bg-white text-center;
}

.nav-links > a.router-link-active {
  @apply bg-neutral-900/60 dark:bg-slate-500/60 hover:text-white;
}
</style>
