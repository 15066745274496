<template>
  <div class="bg-neutral-800 text-white h-11 border-b border-neutral-500">
    <div class="flex px-4 md:container md:mx-auto h-full">
      <div class="flex-1 flex items-center -ml-2">
        <a
          href="https://www.lanarkgreyfriars.com/"
          title="Home"
          class="nav-item hover:bg-cyan-500"
        >
          <HomeIcon class="h-5 inline" />
          <span class="hidden sm:inline ml-1">Home</span>
        </a>
        <a
          href="https://www.lanarkgreyfriars.com/ondemand"
          title="On Demand"
          class="hover:bg-purple-500"
        >
          <PlayIcon class="h-5 inline" />
          <span class="hidden sm:inline ml-1">On Demand</span>
        </a>
      </div>
      <div class="flex items-center -mr-2">
        <a
          href="https://twitter.com/langreyfriars"
          title="Follow @langreyfriars on Twitter!"
          class="hover:bg-sky-500"
        >
          <font-awesome-icon :icon="['fab', 'twitter-square']" size="lg" />
        </a>
        <a
          href="https://facebook.com/langreyfriars"
          title="Like Greyfriars on Facebook!"
          class="hover:bg-blue-500"
        >
          <font-awesome-icon :icon="['fab', 'facebook-square']" size="lg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { HomeIcon, PlayIcon } from "@heroicons/vue/solid";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  components: {
    HomeIcon,
    PlayIcon,
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
a {
  @apply inline-flex items-center transition-all mx-1 px-2 h-8 rounded-md;
}
</style>
