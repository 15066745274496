import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/live'
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import(/* webpackChunkName: "live" */ '../views/Live.vue')
  },
  {
    path: '/live-preview',
    name: 'Live Preview',
    component: () => import(/* webpackChunkName: "live" */ '../views/Live.vue'),
    props: { isPreview: true }
  },
  {
    path: '/again',
    name: 'Watch Again',
    component: () => import(/* webpackChunkName: "watchAgain" */ '../views/WatchAgain.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
