<template>
  <GlobalNavigation />
  <NavigationBar :up-next="upNext" />
  <div class="bg-white dark:bg-black py-4">
    <div class="px-4 md:container md:mx-auto">
      <router-view></router-view>
    </div>
  </div>
  <Footer />
</template>

<script>
import GlobalNavigation from "@/components/GlobalNavigation.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  data() {
    return {
      upNext: "more from Greyfriars on Demand",
    };
  },
  components: { GlobalNavigation, NavigationBar, Footer },
  mounted() {
    axios
      .get(this.$externals.upNext)
      .then((response) => {
        this.upNext = response.data.Data;
      })
      .catch(function (error) {
        console.log(`upNext error: ${error}`);
      });
  },
};
</script>
