import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { externals } from './externals.js'
import './main.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitterSquare, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
library.add(faTwitterSquare, faFacebookSquare);

const app = createApp(App)
app.config.globalProperties.$externals = externals
app.use(router).mount('#app')
