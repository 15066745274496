<template>
  <div
    class="border-t border-gray-300 bg-gray-50 dark:border-gray-700 dark:bg-gray-900 min-h-full py-3"
  >
    <div class="px-4 md:container md:mx-auto flex">
      <div
        class="prose dark:prose-invert grow max-w-none text-gray-600 dark:text-gray-50 text-sm"
      >
        © {{ year }}
        <a href="http://www.lanarkgreyfriars.com">Greyfriars Parish Church, Lanark</a>, a
        congregation of the
        <a href="http://www.churchofscotland.org.uk">Church of Scotland</a>, and a
        registered Scottish charity, SC016504.
      </div>
      <div>
        <img src="@/assets/woag-colour-right.svg" class="h-8" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
